var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('b-table',{ref:"table",attrs:{"data":_vm.data,"detailed":"","hoverable":"","custom-detail-row":"","opened-detailed":['Board Games'],"default-sort":['name', 'asc'],"detail-key":"name","show-detail-icon":_vm.showDetailIcon},on:{"details-open":(row, index) => _vm.$buefy.toast.open(`Expanded ${row.name}`)},scopedSlots:_vm._u([{key:"detail",fn:function(props){return _vm._l((props.row.items),function(item){return _c('tr',{key:item.name},[(_vm.showDetailIcon)?_c('td'):_vm._e(),_c('td',[_vm._v("    "+_vm._s(item.name))]),_c('td',{staticClass:"has-text-centered"},[_vm._v(_vm._s(item.sold))]),_c('td',{staticClass:"has-text-centered"},[_vm._v(_vm._s(item.available))]),_c('td',{staticClass:"has-text-centered"},[_c('span',{class:[
                            'tag',
                            {'is-danger': item.sold / item.available <= 0.45},
                            {'is-success': item.sold / item.available > 0.45}
                        ]},[_vm._v(" "+_vm._s(Math.round((item.sold / item.available) * 100))+"% ")])])])})}}])},[_c('b-table-column',{attrs:{"field":"name","label":_vm.columnsVisible['name'].title,"width":"300","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.showDetailIcon)?[_vm._v(" "+_vm._s(props.row.name)+" ")]:[_c('a',{on:{"click":function($event){return _vm.toggle(props.row)}}},[_vm._v(" "+_vm._s(props.row.name)+" ")])]]}}])}),_c('b-table-column',{attrs:{"field":"sold","label":_vm.columnsVisible['sold'].title,"sortable":"","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.sold)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"available","label":_vm.columnsVisible['available'].title,"sortable":"","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.available)+" ")]}}])}),_c('b-table-column',{attrs:{"label":_vm.columnsVisible['cleared'].title,"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
                        'tag',
                        {'is-danger': props.row.sold / props.row.available <= 0.45},
                        {'is-success': props.row.sold / props.row.available > 0.45}
                    ]},[_vm._v(" "+_vm._s(Math.round((props.row.sold / props.row.available) * 100))+"% ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }