<template>
    <section>
        <b-table
            :data="data"
            ref="table"
            detailed
            hoverable
            custom-detail-row
            :opened-detailed="['Board Games']"
            :default-sort="['name', 'asc']"
            detail-key="name"
            @details-open="(row, index) => $buefy.toast.open(`Expanded ${row.name}`)"
            :show-detail-icon="showDetailIcon">

            <b-table-column
                field="name"
                :label="columnsVisible['name'].title"
                width="300"
                sortable
                v-slot="props"
            >
                <template v-if="showDetailIcon">
                    {{ props.row.name }}
                </template>
                <template v-else>
                    <a @click="toggle(props.row)">
                        {{ props.row.name }}
                    </a>
                </template>
            </b-table-column>

            <b-table-column
                field="sold"
                :label="columnsVisible['sold'].title"
                sortable
                centered
                v-slot="props"
            >
                {{ props.row.sold }}
            </b-table-column>

            <b-table-column
                field="available"
                :label="columnsVisible['available'].title"
                sortable
                centered
                v-slot="props"
            >
                {{ props.row.available }}
            </b-table-column>

            <b-table-column
               :label="columnsVisible['cleared'].title"
                centered
                v-slot="props"
            >
                <span :class="
                        [
                            'tag',
                            {'is-danger': props.row.sold / props.row.available <= 0.45},
                            {'is-success': props.row.sold / props.row.available > 0.45}
                        ]">
                    {{ Math.round((props.row.sold / props.row.available) * 100) }}%
                </span>
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <tr v-for="item in props.row.items" :key="item.name">
                    <td v-if="showDetailIcon"></td>
                    <td >&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}</td>
                    <td class="has-text-centered">{{ item.sold }}</td>
                    <td class="has-text-centered">{{ item.available }}</td>
                    <td class="has-text-centered">
                        <span :class="
                            [
                                'tag',
                                {'is-danger': item.sold / item.available <= 0.45},
                                {'is-success': item.sold / item.available > 0.45}
                            ]">
                            {{ Math.round((item.sold / item.available) * 100) }}%
                        </span>
                    </td>
                </tr>
            </template>
        </b-table>

    </section>
</template>

<script>
    export default {
        data() {
            return {
                data: [
                    {
                        name: 'Board Games',
                        sold: 131,
                        available: 301,
                        items: [
                            {
                                name: 'Monopoly',
                                sold: 57,
                                available: 100
                            },
                            {
                                name: 'Scrabble',
                                sold: 23,
                                available: 84
                            },
                            {
                                name: 'Chess',
                                sold: 37,
                                available: 61
                            },
                            {
                                name: 'Battleships',
                                sold: 14,
                                available: 56
                            }
                        ]
                    },
                ],
                columnsVisible: {
                    name: { title: 'Name', display: true },
                    sold: { title: 'Stock Sold', display: true },
                    available: { title: 'Stock Available', display: true },
                    cleared: { title: 'Stock Cleared', display: true },
                },
                showDetailIcon: true
            }
        },
        methods: {
            toggle(row) {
                this.$refs.table.toggleDetails(row)
            }
        }
    }
</script>